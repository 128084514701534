<template>
  <div class="login_box">
    <el-row type="flex" class="login">
      <div class="login_right">
        <div class="login_content">
          <h2>
            {{ appName }}
          </h2>
          <h4>{{ $t("authorize1") }}:</h4>
          <p>
            <s></s>
            <span>
              {{ $t("authorize2") }}
            </span>
          </p>
          <p>
            <s></s>
            <span>
              {{ $t("authorize3") }}
            </span>
          </p>
          <button @click="agreeLogin()">
            {{ $t("authorize4") }}
          </button>
          <i @click="cancelLogin()">
            {{ $t("cancel") }}
          </i>
        </div>
        <span class="user_login">
          {{ $t("authorize5") }}
        </span>
      </div>
    </el-row>
  </div>
</template>

<script>
import {
  getClient,
  authorizeCheck,
  authorizeLoginOut,
} from "@/api/authorizeLogin_api.js";
import { walletApi } from "../common/js/baseApi";
export default {
  data() {
    return {
      appName: "",
      jwtToken: "",
    };
  },
  methods: {
    agreeLogin() {
      let token = this.jwtToken;
      authorizeCheck(token)
        .then(() => {
          let data = this.$route.query;

          const redirectUri = encodeURIComponent(
            data.redirect_uri + "/?chain=" + this.$store.state.chain
          );

          let Url = `${walletApi()}/oauth2/oauth/authorize?response_type=code&client_id=${
            data.client_id
          }&redirect_uri=${redirectUri}&scope=app&user_token=${token}&chain=${
            this.$store.state.chain
          }`;
          window.location.href = Url;
        })
        .catch((err) => {
          console.log("err", err);
          localStorage.removeItem("__Secure-SSID");
          this.$message.error("" + err.message);
          return this.gotoUrl("userLogin", this.$route.query);
        });
    },
    cancelLogin() {
      let token = this.jwtToken;
      authorizeLoginOut(token).then((res) => {
        // console.log(res)
        console.log("删除密码");
        localStorage.clear();
        sessionStorage.clear();
        this.$cookie.remove("metaToken");
        this.$cookie.remove("userAddress");
        localStorage.removeItem("metaToken");
        localStorage.removeItem("__Secure-SSID");
        localStorage.removeItem("loginType");
        this.$router.go(-1);
        // this.gotoUrl('userLogin', this.$route.query)
        // window.location.href = this.$route.query.redirect_uri
      });
    },
  },

  created() {
    let userData = JSON.parse(localStorage.getItem("localuserData")) || "";
    console.log(userData);
    if (userData) {
      let params = {
        clientId: this.$route.query.client_id,
      };
      let jwt = this.$cookie.get("metaToken");
      if (!jwt) {
        jwt = localStorage.getItem("metaToken");
      }
      this.jwtToken = jwt;
      console.log("jwtToken", this.jwtToken);
      getClient(params, this.jwtToken)
        .then((res) => {
          this.appName = res.name;
          localStorage.setItem("appName", this.appName);
        })
        .catch((err) => {
          this.$cookie.remove("metaToken");
          this.$cookie.remove("userAddress");
          console.log("删除密码");
          localStorage.removeItem("metaToken");
          localStorage.removeItem("__Secure-SSID");
          this.$message.error("" + err.message || err);
          return this.gotoUrl("userLogin", this.$route.query);
        });
    } else {
      this.gotoUrl("userLogin", this.$route.query);
    }
  },
};
</script>

<style lang="scss" scoped>
.login_box {
  width: 100vw;
  min-height: calc(100vh - 68px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f7fa;
}
.login {
  max-width: 720px;
  background: url("../assets/images/background.png") no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  height: 600px;
  display: flex;
  min-width: 600px;
  align-items: center;
  justify-content: center;
}
.user_login {
  display: block;
  text-align: center;
  color: #909399;
  font-size: 12px;
  margin-top: 40px;
}
.login_right {
  padding: 20px;
}
.login_content {
  max-width: 390px;
  background: #fff;
  margin: 0 auto;
  border-radius: 17px;
  position: relative;
  padding: 50px;
  min-height: 450px;
  min-width: 375px;
  &::before {
    content: "";
    background: url("../assets/images/backgroun_item.png") no-repeat;
    width: 54px;
    height: 54px;
    position: absolute;
    right: -27px;
    bottom: 66px;
  }
  h2 {
    font-size: 24px;
    color: rgba(48, 49, 51, 1);
    text-align: center;
  }
  h4 {
    margin-top: 45px;
    font-size: 16px;
    color: #303133;
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 12px;
    color: #909399;
    font-size: 14px;
    display: flex;
    align-items: center;
    s {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #eab300;
      border-radius: 50%;
      margin-right: 7px;
    }
  }
  i {
    color: #909399;
    display: block;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
  }
  button {
    width: 260px;
    margin-top: 70px;
    height: 60px;
    cursor: pointer;
    background: rgba(234, 179, 0, 1);
    border-radius: 8px;
    color: #fff;
  }
}
@media only screen and (max-width: 768px) {
  .login_left {
    height: calc(100vh - 80px);
  }
  .login_right {
    padding-bottom: 60px;
  }
  .login_left .app_downloads a {
    font-size: 12px;
  }
}
@media only screen and (max-width: 450px) {
  .login_content {
    max-width: 330px;
  }
}
</style>
